var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-box" }, [
    _c("div", { staticClass: "login-content" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "input-box" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "reg-ruleForm",
              attrs: {
                "label-position": "top",
                rules: _vm.rules,
                model: _vm.ruleForm,
                "label-width": "100px",
              },
            },
            [
              _vm.step == "step1"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { position: "relative" },
                          attrs: { prop: "name" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "code-btn theme-color",
                              on: { click: _vm.getCode },
                            },
                            [_vm._v(_vm._s(_vm.codeText))]
                          ),
                          _c("el-input", {
                            attrs: {
                              "prefix-icon": "el-icon-user",
                              placeholder: "请输入国内手机号/邮箱",
                            },
                            model: {
                              value: _vm.ruleForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "name", $$v)
                              },
                              expression: "ruleForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "code" } },
                        [
                          _c("el-input", {
                            attrs: {
                              "prefix-icon": "el-icon-key",
                              placeholder: "请输入验证码",
                            },
                            model: {
                              value: _vm.ruleForm.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "code", $$v)
                              },
                              expression: "ruleForm.code",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "flex-box step1-box" }, [
                        _c(
                          "div",
                          {
                            staticClass: "wid-btn",
                            on: { click: _vm.toLogin },
                          },
                          [_vm._v("返回登录")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "wi-btn",
                            on: { click: _vm.nextStep },
                          },
                          [_vm._v("下一步")]
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step == "step2"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "password1" } },
                        [
                          _c("el-input", {
                            attrs: {
                              "prefix-icon": "el-icon-lock",
                              type: "password",
                              placeholder: "请输入密码",
                            },
                            model: {
                              value: _vm.ruleForm.password1,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "password1", $$v)
                              },
                              expression: "ruleForm.password1",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "password2" } },
                        [
                          _c("el-input", {
                            attrs: {
                              "prefix-icon": "el-icon-lock",
                              type: "password",
                              placeholder: "请再次输入密码",
                            },
                            model: {
                              value: _vm.ruleForm.password2,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "password2", $$v)
                              },
                              expression: "ruleForm.password2",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "flex-box" }, [
                        _c(
                          "div",
                          {
                            staticClass: "wid-btn def-btn",
                            staticStyle: { width: "96%" },
                            on: { click: _vm.handleRegister },
                          },
                          [_vm._v("注册")]
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo" }, [
      _c("img", { attrs: { src: require("@/assets/img/mu-logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }