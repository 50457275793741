<template>
<div class="login-box">
  <div class="login-content">
    <div class="logo">
      <img src="@/assets/img/mu-logo.png"/>
    </div>
    <div class="input-box">
   <el-form :label-position="'top'" :rules="rules" :model="ruleForm"  ref="ruleForm" label-width="100px" class="reg-ruleForm">
    <div v-if="step=='step1'">
      <!-- 第一步校验账号是否已经注册 -->
      <el-form-item prop="name" style="position:relative">
        <div class="code-btn theme-color" @click="getCode">{{ codeText }}</div>
        <el-input v-model="ruleForm.name" prefix-icon="el-icon-user" placeholder="请输入国内手机号/邮箱"></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-input v-model="ruleForm.code" prefix-icon="el-icon-key" placeholder="请输入验证码" ></el-input>
      </el-form-item>
      <div class="flex-box step1-box">
        <div class="wid-btn" @click="toLogin">返回登录</div>
        <div class="wi-btn" @click="nextStep">下一步</div>
      </div>
    </div>
    <div v-if="step=='step2'">
      <el-form-item prop="password1">
        <el-input prefix-icon="el-icon-lock" type="password" placeholder="请输入密码" v-model="ruleForm.password1"></el-input>
      </el-form-item>
      <el-form-item prop="password2">
        <el-input prefix-icon="el-icon-lock" type="password" placeholder="请再次输入密码" v-model="ruleForm.password2"></el-input>
      </el-form-item>
      <div class="flex-box">
        <div class="wid-btn def-btn" style="width:96%" @click="handleRegister">注册</div>
      </div>
    </div>
  </el-form>
    </div>
  </div>
</div>
</template>
<script>
export default {
  data(){
    var validateName = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入国内手机号/邮箱'));
        } else if (value &&(value.indexOf('@')!=-1)&&this.checkEmail(value)==false) {
          callback(new Error('请输入正确的邮箱格式或国内手机号'));
        } else if (value &&value.indexOf('@')==-1&&this.checkPhone(value)==false) {
          callback(new Error('请输入正确的邮箱格式或国内手机号'));
        } else {
          callback();
        }
    };
    var validatePass1 = (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入密码'));
        } else if (this.checkPwd(value)==false) {
          callback(new Error('密码格式为6~16位的数字字母'));
        }else{
          if (this.ruleForm.password2 !== '') {
            this.$refs.ruleForm.validateField('password2');
          }
          callback();
        }
      };
    var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.password1) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
    return{
      step:'step1',
      codeText:'获取验证码',
      date:60,
      disabledshow:false,
      ruleForm:{
       name:'',
       password1:'',
       password2:'',
       code:'',
       timer:null
      },
      rules: {
          name: [
            { required: true, message: '请输入国内手机号/邮箱', trigger: 'blur' },
            { validator: validateName, trigger: 'blur' }
          ],
          password1: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { validator: validatePass1, trigger: 'blur' }
          ],
          password2: [
            { required: true, message: '请再次输入密码', trigger: 'blur' },
            { validator: validatePass2, trigger: 'blur' }
          ],
          code: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ],
        }
    }
  },
  methods:{
    toLogin(){
      this.$router.push({
        name:"login"
      })
    },
    // 获取验证码
    getCode() {
      if(this.disabledshow){
        return false
      }
      if (this.ruleForm.name === '') {
        this.$refs.ruleForm.validateField('name');
      } else {
        this.timer = null
        let params = {
          account:this.ruleForm.name,
          action:'reg'
        }
        this.$store
          .dispatch('auth/getcodes',params)
          .then(res => {
            if (res.code === 0) {
              this.disabledshow = true
              if (this.date === 60) {
                this.timer = setInterval(() => {
                  this.date--
                  this.codeText = this.date + 's后重新获取'
                  if (this.date === 0) {
                    this.date = 60
                    this.disabledshow = false
                    this.codeText = '重新获取'
                    clearInterval(this.timer)
                  }
                }, 1000)
              }
            } else {
              this.disabledshow = false
              this.date = 60
              this.codeText = '重新获取'
              clearInterval(this.timer)
            }
          })
          .catch(err => {
            this.text = err.body.msg
          })
      }
    },
    // 下一步，校验验证码是否正确
  nextStep(){
    // 校验验证码
   
    this.$refs.ruleForm.validate((valid) => {
      if (valid) {
        let params = {
          type:'verify',
          account:this.ruleForm.name,
          code:this.ruleForm.code
        }
        this.$store
          .dispatch('auth/checkscodes',params)
          .then(res => {
            if(res.msg=='验证码正确'){
              this.step = 'step2'
            }
        }) 
      }else{
        console.log("cuowu")
      }
    })
  },
    // 创建用户
    handleRegister(){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = {
            way:this.ruleForm.name.indexOf('@')==-1?'tel':'email',
            password:this.ruleForm.password1,
            verify:this.ruleForm.code,
            type:1,
            refer:'h5'
          }
          if(this.ruleForm.name.indexOf('@')==-1){
            data.tel = this.ruleForm.name
          }else {
            data.email =  this.ruleForm.name
          }
          this.$store.dispatch('auth/createUser', data).then(() => {
            this.$router.push({path:'auth',query:{type:"re"}})
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.login-box{
  padding:.8rem;
  .login-content{
    background: #fff;
    border-radius:8px;
    height: calc(100vh - 1.6rem);
    .flex-box{
      justify-content: space-between;
    }
  }

  .logo{
    text-align: center;
    padding-top:7rem;
    margin-bottom:1rem;
    img{
      width: 45%;
      margin-bottom:1rem;
    }
    .logo-name{
      letter-spacing: 2px;
      margin-bottom: 1rem;
    }
  }
  .sub-name{
    text-align: center;
    color: #989898;
    font-size: .9rem;
  }
  .btn-box{
    text-align: center;
    button{
      width: 80%;
    }
    margin: 1rem;
  }
}
.input-box{
  width: 90%;
  margin-left:5%;
  .username{
    margin-bottom:1rem;
  }
  .el-input{
    width: 100%;
  }
  button{
    width: 96%;
  }
  .error-tips{
    font-size: 1rem;
    color: #f56c6c;
  }
}
.foget{
  height: .8rem;
  text-align: right;
  font-size: .8rem;
  cursor: pointer;
}
.code-btn{
  position:absolute;
  right:10px;
  z-index:3;
  font-size:14px;
}
.def-btn{
  width:100%;
  text-align:center;
  cursor: pointer;
}
.step1-box{
  justify-content: space-between;
  div{
    width: 45%;
    text-align:center;
    cursor: pointer;
  }
}
</style>
